import { validateEmail } from "@/utils/regex";
import styles from "./Step.module.scss";

export const StepFour = ({
  handleInputChange,
  errorFormData,
  formData,
  setErrorFormData,
  messages,
}) => {
  const onBlurChange = (e) => {
    if (formData.mail.trim() !== "" && !validateEmail(e.target.value)) {
      setErrorFormData((prevErrors) => ({
        ...prevErrors,
        mail: "Email incorrecto",
      }));
    } else {
      setErrorFormData((prevErrors) => ({
        ...prevErrors,
        mail: "",
      }));
    }
  };
  const handleInputChanges = (name, value) => {
    handleInputChange(name, value);
    setErrorFormData((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  return (
    <div className={styles.dialogText}>
      <div className={styles.titleDescription}>
        <h2>{messages.title}</h2>
        <p>{messages.description}</p>
      </div>
      <form className={styles.formInputContainer}>
        <div className={styles.inputContainer}>
          <input
            type="text"
            name="full_name"
            value={formData.full_name}
            onChange={(e) => handleInputChanges("full_name", e.target.value)}
            className={`${formData.full_name ? styles.noEmpty : ""} ${
              errorFormData.full_name ? styles.borderError : ""
            }`}
          />
          <label htmlFor="full_name">{messages.name}</label>
          {errorFormData?.full_name ? (
            <span>{errorFormData.full_name}</span>
          ) : null}
        </div>

        <div className={styles.inputContainer}>
          <input
            type="email"
            name="mail"
            value={formData.mail}
            onBlur={(e) => {
              onBlurChange(e);
            }}
            onChange={(e) => handleInputChanges("mail", e.target.value)}
            className={`${formData.mail ? styles.noEmpty : ""} ${
              errorFormData.mail ? styles.borderError : ""
            }`}
          />
          <label
            htmlFor="email"
            className={errorFormData.mail ? styles.errorLabel : ""}
          >
            {messages.email}
          </label>
          {errorFormData?.mail ? (
            <span className={styles.errorMessage}>{errorFormData.mail}</span>
          ) : null}
        </div>

        <div className={styles.inputContainer}>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={(e) => handleInputChanges("country", e.target.value)}
            className={`${formData.country ? styles.noEmpty : ""} ${
              errorFormData.country ? styles.borderError : ""
            }`}
          />
          <label htmlFor="country">{messages.country}</label>
          {errorFormData?.country ? <span>{errorFormData.country}</span> : null}
        </div>

        <div className={styles.inputContainer}>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={(e) => {
              const value = e.target.value;
              const isNumeric = /^[0-9]*$/.test(value);
              if (isNumeric || value === "") {
                handleInputChange("phone", value);
                setErrorFormData((prevErrors) => ({
                  ...prevErrors,
                  phone: "",
                }));
              }
            }}
            className={`
            ${formData.phone ? styles.noEmpty : ""}
            ${errorFormData?.phone ? styles.borderError : ""}
            `}
          />
          <label
            htmlFor="phone"
            className={errorFormData.phone ? styles.errorLabel : ""}
          >
            {messages.phone}
          </label>
          {errorFormData?.phone ? (
            <span className={styles.errorMessage}>{errorFormData.phone}</span>
          ) : null}
        </div>

        <div className={styles.areaContainer}>
          <textarea
            name="comment"
            value={formData.comment}
            onChange={(e) => handleInputChanges("comment", e.target.value)}
            className={`
            ${formData.comment ? styles.noEmpty : ""}
            ${errorFormData?.comment ? styles.borderError : ""}
            `}
          />
          <label htmlFor="comment">{messages.comment}</label>
          {errorFormData?.comment ? <span>{errorFormData.comment}</span> : null}
        </div>
      </form>
    </div>
  );
};
