"use client";
import React, { useEffect, useRef, useState } from "react";
import styles from "./SearchBar.module.scss";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";

export const SearchBar = ({
  blogParams = false,
  fullWidth = false,
  isOpen = false,
}) => {
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(isOpen);
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const translate = useTranslations("search");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (blogParams) {
        if (inputRef.current && inputRef.current.value === "") {
          setIsSearchInputOpen(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [blogParams]);

  const handleSearch = () => {
    const searchQuery = inputRef.current?.value;
    if (searchQuery) {
      inputRef.current.value = "";
      router.push(`/blog/search/${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <div
      className={`${styles.lens} ${fullWidth || isSearchInputOpen ? styles.active : ""} ${fullWidth ? styles.fullWidth : ""}`}
      onClick={() => setIsSearchInputOpen(true)}
    >
      <div className={styles.icon} onClick={handleSearch}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7151_20786)">
            <path
              d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
              fill="var(--primary)"
            />
          </g>
          <defs>
            <clipPath id="clip0_7151_20786">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <input
        ref={inputRef}
        type="text"
        placeholder={translate("searchPlaceholder")}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.code === "Enter") {
            handleSearch();
          }
        }}
      />
    </div>
  );
};
