"use client";

import { Header } from "@/components/layout/Header/Header";
import { Cursor } from "@/components/common/Cursor/Cursor";
import { useEffect, useRef, useState } from "react";
import { useRefStore } from "../../store/ref-store";
import useModelStore from "../../store/model-store";
import { loadThreeModels } from "../../load-models/LoadModels";
import { Dialog } from "@/components/common/Dialog/Dialog";

export function LayoutHolder({ children }) {
  const contentRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [totalAssets, setTotalAssets] = useState(0);
  const [loadedAssets, setLoadedAssets] = useState(0);

  const { duoDuckModel } = useModelStore();

  //Loading logic main function
  const loadAllAssets = async () => {
    const imageSources = await getAllImages();
    const videoSources = await getAllVideos();

    const total = imageSources.length + videoSources.length;

    if (total > 0) {
      setTotalAssets(total);

      await Promise.all([
        loadAllImages(imageSources),
        loadAllVideos(videoSources),
      ]);
    } else {
      setTotalAssets(1);
      setLoadedAssets(1);
    }

    useRefStore.setState({ websiteLoading: false });
  };

  //Images loading functions
  const getAllImages = async () => {
    const images = document.querySelectorAll("img");
    const sourcesSet = new Set();

    images.forEach((element) => {
      const src = element.getAttribute("src");
      if (src) {
        sourcesSet.add(src);
      }
    });

    const sources = Array.from(sourcesSet);
    return sources;
  };

  const loadAllImages = async (sources) => {
    const loadPromises = sources.map(
      (src) =>
        new Promise((resolve) => {
          const image = new Image();
          image.onload = resolve;
          image.onerror = resolve;
          image.src = src;
        })
    );

    await Promise.all(loadPromises);
    setLoadedAssets((prev) => prev + sources.length);
  };

  //Videos loading functions
  const getAllVideos = async () => {
    const videos = document.querySelectorAll("video");
    const sourcesSet = new Set();

    videos.forEach((element) => {
      const source = element.children[0].getAttribute("src");
      if (source) {
        sourcesSet.add(source);
      }
    });

    const sources = Array.from(sourcesSet);
    return sources;
  };

  const loadAllVideos = async (sources) => {
    const loadPromises = sources.map(
      (src) =>
        new Promise((resolve) => {
          const video = document.createElement("video");
          video.onloadeddata = resolve;
          video.onerror = resolve;
          video.src = src;
        })
    );

    await Promise.all(loadPromises);
    setLoadedAssets((prev) => prev + sources.length);
  };

  //Trigger function to start loading everything
  useEffect(() => {
    if (typeof window !== "undefined" && duoDuckModel !== null) {
      loadAllAssets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duoDuckModel]);

  useEffect(() => {
    loadThreeModels();
    if (
      typeof window !== undefined &&
      window.matchMedia("(min-width: 768px)").matches
    ) {
      setIsMobile(false);
      useRefStore.setState({ isMobile: false });
    }
    if (
      typeof window !== undefined &&
      window.matchMedia("(max-width: 767px)").matches
    ) {
      setIsMobile(true);
      useRefStore.setState({ isMobile: true });
    }
  }, []);

  useEffect(() => {
    const handler = () => {
      useRefStore.setState({ fromPopState: true });
    };

    window.addEventListener("popstate", handler);

    return () => {
      window.removeEventListener("popstate", handler);
    };
  }, []);

  return (
    <>
      {!isMobile && <Cursor />}
      <Header />
      <Dialog />
      <main
        ref={contentRef}
        style={{
          backgroundColor: "var(--background)",
        }}
        className="mainWebWrapper"
      >
        {children}
      </main>
    </>
  );
}
