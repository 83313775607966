import gsap from "gsap";
import styles from "./Burger.module.scss";
import { Nav } from "./Nav/Nav";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const Burger = ({ isActive, setIsActive, setIsContactOpen }) => {
  const burgerBTN = useRef(null);
  const [theme, setTheme] = useState(styles.primaryTheme);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleCloseMenu = () => {
    setIsActive(false);
  };

  useEffect(() => {
    const handleBurgerColored = (event) => {
      if (event.detail) setTheme(styles.secundaryTheme);
      else setTheme(styles.primaryTheme);
    };

    window.addEventListener("burgerColoredEvent", handleBurgerColored);

    return () =>
      window.removeEventListener("burgerColoredEvent", handleBurgerColored);
  }, []);

  return (
    <>
      <button
        aria-label="Open the menu"
        onClick={toggleMenu}
        className={`${styles.burger} ${isActive ? styles.burgerActive : theme}`}
        ref={burgerBTN}
      ></button>

      <AnimatePresence mode="wait">
        {isActive && (
          <Nav
            handleCloseMenu={handleCloseMenu}
            setIsContactOpen={setIsContactOpen}
          />
        )}
      </AnimatePresence>
    </>
  );
};
