import Link from "next/link";
import { FC } from "react";
import styles from "./ChipButton.module.scss";

interface IChipButton {
  label?: string;
  onClick?: () => void;
  variant?: string;
  href?: string;
  hidden?: boolean;
}

export const ChipButton: FC<IChipButton> = ({
  label,
  onClick,
  variant = "accent",
  href,
  hidden,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  if (href) {
    return (
      <Link
        href={href}
        className={`${styles.chipButton} ${styles[variant]} chipBtn  ${
          hidden ? styles.hidden : ""
        }`}
        onClick={handleClick}
      >
        {label}
      </Link>
    );
  } else {
    return (
      <button
        className={`${styles.chipButton} ${styles[variant]} chipBtn  ${
          hidden ? styles.hidden : ""
        }`}
        onClick={handleClick}
      >
        {label}
      </button>
    );
  }
};
