import { Dispatch, MutableRefObject, SetStateAction } from "react";
import gsap from "gsap";

export class BaseCursorUtilities {
  constructor(
    public sizeSetter: Function,
    public vizSetter: Function,
    public xSetter: Function,
    public ySetter: Function,
    public isHover: MutableRefObject<boolean>,
    public hovered: MutableRefObject<HTMLElement | null>,
    public mousePos: MutableRefObject<number[]>,
    public setActiveHover: Dispatch<SetStateAction<string>>
  ) {}

  // Trigger on element hover
  CursorHover(targetElement: HTMLElement) {
    const elementHoverAttribute = targetElement.getAttribute("data-hover");

    if (
      elementHoverAttribute === "hcards" ||
      elementHoverAttribute === "hcards-social" ||
      elementHoverAttribute === "popup-close"
    ) {
      this.vizSetter("visible");
    } else {
      this.sizeSetter(158);
      this.vizSetter("visible");
    }
  }

  // Trigger when cursor leaves element
  CursorLeave() {
    this.sizeSetter(0);
    this.vizSetter("hidden");
    this.isHover.current = false;
    this.hovered.current = null;
  }

  // Handle scroll events
  HandleScroll() {
    if (this.hovered.current === null) return;
    const hoveredBox = this.hovered.current.getBoundingClientRect();
    const [mouseX, mouseY] = this.mousePos.current;

    if (
      mouseX < hoveredBox.left ||
      mouseX > hoveredBox.right ||
      mouseY < hoveredBox.top ||
      mouseY > hoveredBox.bottom
    ) {
      this.CursorLeave();
    }
  }

  // Handle mouse movement
  HandleMouseMoves(
    event: MouseEvent,
    isTransitioning: MutableRefObject<boolean>
  ) {
    const { x, y, target } = event;
    this.mousePos.current = [x, y];
    this.xSetter(x);
    this.ySetter(y);

    const hoverAttr = (target as HTMLElement).getAttribute("data-hover");

    if (hoverAttr === null || isTransitioning.current) {
      this.CursorLeave();
    } else {
      this.hovered.current = target as HTMLElement;
      this.setActiveHover(hoverAttr);
      this.isHover.current = true;
      this.CursorHover(target as HTMLElement);
    }
  }
}

export class AboutCursorUtilities {
  constructor(
    public techHover: MutableRefObject<boolean>,
    public mousePos: MutableRefObject<number[]>,
    public elementClasses: string[],
    public elementOnHover: MutableRefObject<boolean[]>,
    public hovered: MutableRefObject<HTMLElement | null>,
    public setCardsSide: Dispatch<SetStateAction<string>>,
    public tech0XSetter: Function,
    public tech0YSetter: Function,
    public tech1XSetter: Function,
    public tech1YSetter: Function,
    public tech2XSetter: Function,
    public tech2YSetter: Function
  ) {}

  // General method to handle tech hover animation
  private setTechHoverAnimation(index: number, isHovered: boolean) {
    gsap.to(`.${this.elementClasses[index]}`, {
      "--mask-size": isHovered ? "180px" : "0px",
      duration: 0.3,
    });
    this.elementOnHover.current[index] = isHovered;
  }

  // Handle tech hover
  CursorOverTech(element: HTMLElement) {
    this.elementClasses.forEach((className, index) => {
      if (element.classList.contains(className) && !this.techHover.current) {
        this.setTechHoverAnimation(index, true);
      }
    });
    this.techHover.current = true;
  }

  // Handle tech leave
  CursorLeaveTech() {
    this.elementOnHover.current.forEach((isHovered, index) => {
      if (isHovered) this.setTechHoverAnimation(index, false);
    });
    this.hovered.current = null;
    this.elementOnHover.current.fill(false);
    this.techHover.current = false;
  }

  // Handle scroll events
  HandleScroll() {
    if (this.hovered.current === null) return;
    const hoveredBox = this.hovered.current.getBoundingClientRect();
    const [mouseX, mouseY] = this.mousePos.current;

    if (
      mouseX < hoveredBox.left ||
      mouseX > hoveredBox.right ||
      mouseY < hoveredBox.top ||
      mouseY > hoveredBox.bottom
    ) {
      this.CursorLeaveTech();
    }
  }

  // Handle mouse movement
  HandleMouseMove(event: MouseEvent) {
    const { x, y, target } = event;
    this.mousePos.current = [x, y];

    const hoverAttribute = (target as HTMLElement).getAttribute("data-hover");
    this.hovered.current = target as HTMLElement;

    if (hoverAttribute === "tech") {
      this.CursorOverTech(target as HTMLElement);
    } else if (hoverAttribute === null && this.techHover.current) {
      this.CursorLeaveTech();
    }

    if (hoverAttribute === "hcards" || hoverAttribute === "hcards-social") {
      this.setCardsSide(
        hoverAttribute === "hcards-social" ? "social" : `/close.svg`
      );
    } else {
      this.setCardsSide(null);
    }

    this.updateTechElementPositions(event);
  }

  // Update positions for tech elements
  private updateTechElementPositions(event: MouseEvent) {
    const boxes = this.elementClasses.map((cls) =>
      document.querySelector(`.${cls}`).children[2].getBoundingClientRect()
    );

    boxes.forEach((box, index) => {
      const xSetter = this[`tech${index}XSetter`];
      const ySetter = this[`tech${index}YSetter`];
      xSetter(event.x - box.left);
      ySetter(event.y - box.top);
    });
  }
}
