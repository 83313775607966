"use client";
import gsap from "gsap";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useCursorStore } from "../../../store/cursor-store";
import styles from "./Cursor.module.scss";
import { AboutCursorUtilities, BaseCursorUtilities } from "./CursorUtilities";

export const Cursor = () => {
  const { isAudioPlaying } = useCursorStore();
  const [activeHover, setActiveHover] = useState("none");
  const translate = useTranslations("cursor");
  const mousePos = useRef([0, 0]);
  const hovered = useRef(null);
  const isHover = useRef(false);
  const isTransitioning = useRef(false);

  const GetDataContent = useCallback(() => {
    switch (activeHover) {
      case "video":
        return isAudioPlaying ? translate("mute") : translate("active_sound");
      case "cases":
        return translate("view_project");
      case "service":
        return translate("view_service");
      case "join":
        return translate("join_team");
      case "expertise":
        return translate("expertise");
      case "application":
        return translate("apply");
      case "footer-title":
        return translate("contact_us");
      case "hcards":
      case "hcards-social":
      case "popup-close":
        return "";
      default:
        return null;
    }
  }, [activeHover, isAudioPlaying, translate]);

  useLayoutEffect(() => {
    const hasPointer = window.matchMedia("(pointer: fine)").matches;
    if (!hasPointer) return;

    const cursor = document.querySelector(".cursor");
    const xSetter = gsap.quickSetter(cursor, "x", "px");
    const ySetter = gsap.quickSetter(cursor, "y", "px");
    const sizeSetter = gsap.quickSetter(cursor, "--cursor-size", "px");
    const vizSetter = gsap.quickSetter(cursor, "--cursor-content-visibility");

    const baseCursor = new BaseCursorUtilities(
      sizeSetter,
      vizSetter,
      xSetter,
      ySetter,
      isHover,
      hovered,
      mousePos,
      setActiveHover
    );

    const handleScroll = () => baseCursor.HandleScroll();
    const handleMouseMove = (e) =>
      baseCursor.HandleMouseMoves(e, isTransitioning);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
      gsap.killTweensOf(cursor);
    };
  }, [setActiveHover]);

  const [dataContent, setDataContent] = useState(GetDataContent());

  useEffect(() => {
    setDataContent(GetDataContent());
  }, [GetDataContent]);

  const pathname = usePathname();
  const showAboutCursor = pathname === "/nosotros" || pathname === "/en/about";
  const showWorkWithUsCursor =
    pathname === "/trabaja-con-nosotros" || pathname === "/en/join-us";

  return (
    <>
      <div className={`${styles.cursor} cursor`} data-content={dataContent} />
      {showAboutCursor && <AboutPageCursor />}
      {showWorkWithUsCursor && <WorkWithUsPageCursor />}
    </>
  );
};

const AboutPageCursor = () => {
  const techHover = useRef(false);
  const mousePos = useRef([0, 0]);
  const elementClasses = ["techHover0", "techHover1", "techHover2"];
  const elementOnHover = useRef([false, false, false]);
  const hovered = useRef(null);
  const [cardSide, setCardsSide] = useState(null);

  useLayoutEffect(() => {
    const hasPointer = window.matchMedia("(pointer: fine)");
    if (!hasPointer.matches) return;

    const tech0XSetter = gsap.quickSetter(".techHover0", "--mask-x", "px");
    const tech0YSetter = gsap.quickSetter(".techHover0", "--mask-y", "px");
    const tech1XSetter = gsap.quickSetter(".techHover1", "--mask-x", "px");
    const tech1YSetter = gsap.quickSetter(".techHover1", "--mask-y", "px");
    const tech2XSetter = gsap.quickSetter(".techHover2", "--mask-x", "px");
    const tech2YSetter = gsap.quickSetter(".techHover2", "--mask-y", "px");

    const aboutCursor = new AboutCursorUtilities(
      techHover,
      mousePos,
      elementClasses,
      elementOnHover,
      hovered,
      setCardsSide,
      tech0XSetter,
      tech0YSetter,
      tech1XSetter,
      tech1YSetter,
      tech2XSetter,
      tech2YSetter
    );

    const handleScroll = () => aboutCursor.HandleScroll();
    const handleMouseMove = (e) => aboutCursor.HandleMouseMove(e);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
      gsap.killTweensOf(".techHover0, .techHover1, .techHover2");
    };
  }, []);

  useLayoutEffect(() => {
    const size = window.innerWidth < 1024 ? "40px" : "80px";

    let ctx = gsap.context(() => {
      if (cardSide && cardSide !== "social") {
        gsap.to(".cursor", {
          "--cursor-size": size,
          "--border-width": "1px",
          "--border-color": "var(--primary)",
          "--bg-color": "transparent",
          "--cursor-content-visibility": "visible",
          backgroundColor: "transparent",
          "--bg-img": `url(${cardSide})`,
          "--bg-size": "30% 30%",
          ease: "power1.inOut",
          duration: 0.2,
        });
      } else {
        gsap.to(".cursor", {
          "--cursor-size": "0px",
          "--border-width": "0px",
          "--bg-color": "var(--cursor-color)",
          "--cursor-content-visibility": "hidden",
          backgroundColor: "var(--cursor-color)",
          "--bg-img": "none",
          ease: "power1.inOut",
          duration: 0.2,
        });
      }
    });

    return () => {
      ctx.revert();
    };
  }, [cardSide]);

  return null;
};

const WorkWithUsPageCursor = () => {
  const [closePopUp, setClosePopUp] = useState(false);

  useEffect(() => {
    const handleMouseMovement = (e) => {
      const dataHover = e.target.getAttribute("data-hover");
      setClosePopUp(dataHover === "popup-close");
    };

    window.addEventListener("mousemove", handleMouseMovement);

    return () => {
      window.removeEventListener("mousemove", handleMouseMovement);
    };
  }, []);

  useLayoutEffect(() => {
    const size = window.innerWidth < 1024 ? "40px" : "80px";

    let ctx = gsap.context(() => {
      if (closePopUp) {
        gsap.to(".cursor", {
          "--cursor-size": size,
          "--border-width": "1px",
          "--border-color": "var(--primary)",
          "--bg-color": "transparent",
          "--cursor-content-visibility": "visible",
          backgroundColor: "transparent",
          "--bg-img": `url(/close.svg)`,
          "--bg-size": "30% 30%",
          ease: "power1.inOut",
          duration: 0.2,
        });
      } else {
        gsap.to(".cursor", {
          "--cursor-size": "0px",
          "--border-width": "0px",
          "--bg-color": "var(--cursor-color)",
          "--cursor-content-visibility": "hidden",
          backgroundColor: "var(--cursor-color)",
          "--bg-img": "none",
          ease: "power1.inOut",
          duration: 0.2,
        });
      }
    });

    return () => {
      ctx.revert();
    };
  }, [closePopUp]);

  return null;
};

/* DO NOT REMOVE - TEMPORARY COMMENTED CODE 

   Handle cursor hover over cards in Team Lider Section (about page) (currently unused)

   if (hoverAttr === "cardsCircle") {
     if (isTransitioning.current) return;
     const bounds = hovered.current.getBoundingClientRect();
     mousePos.current[0] - bounds.left < bounds.width / 2
       ? setCardsSide(`/about_team_lider/left-arrow.svg`)
       : setCardsSide(`/about_team_lider/right-arrow.svg`);
     return;
   } else {
     setCardsSide(null);
   }

*/
