import React, { useTransition } from "react";
import styles from "./LanguageSwitch.module.scss";
import { useLocale } from "next-intl";
import { usePathname, useRouter } from "@/navigation";

export default function LanguageSwitch() {
  const [isPending, startTransition] = useTransition();
  const router = useRouter();
  const localActive = useLocale();
  const pathname = usePathname();
  const isChecked = localActive === "en";
  const isDynamicRoute = pathname.includes("[") && pathname.includes("]");

  const handleLanguageSwitch = (targetLocale) => {
    startTransition(() => {
      if (isDynamicRoute) {
        window.location.href = `/${targetLocale}`;
      } else {
        router.replace(pathname as any, { locale: targetLocale });
        window.location.href = `/${targetLocale}${pathname}`;
      }
    });
  };

  const handleClick = () => {
    const targetLocale = isChecked ? "es" : "en";
    handleLanguageSwitch(targetLocale);
  };

  return (
    <button
      disabled={isPending}
      onClick={handleClick}
      className={styles.buttonLanguage}
    >
      {isChecked ? "¡Español, por favor!" : "English, please!"}
    </button>
  );
}
