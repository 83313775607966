import(/* webpackMode: "eager", webpackExports: ["LayoutHolder"] */ "/vercel/path0/app/[locale]/layoutHolder.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/_styles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Hotjar"] */ "/vercel/path0/components/common/HotJar/HotJar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Sora\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-sora\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"sora\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../assets/fonts/avantGardeLight.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../assets/fonts/AvantGardeBold.ttf\",\"weight\":\"800\",\"style\":\"normal\"}],\"variable\":\"--font-avant\",\"display\":\"swap\"}],\"variableName\":\"avantGarde\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ContactProvider"] */ "/vercel/path0/provider/ContextForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DuckProvider"] */ "/vercel/path0/provider/DuckContext.tsx");
