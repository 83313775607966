import { useState } from "react";
import { motion } from "framer-motion";
import styles from "./Nav.module.scss";
import { navItems } from "./NavUtils";
import { useLocale, useTranslations } from "next-intl";
import LinkTransition from "../../../../../transition/components/LinkTransition/LinkTransition";
import { usePathname } from "next/navigation";

interface MaskTextProps {
  hoverText: string;
  classes?: string;
  isHovered: boolean;
}

const MaskText = ({ hoverText, isHovered, classes }: MaskTextProps) => {
  const animation = {
    initial: { y: "100%" },
    enter: {
      y: "0",
      transition: {
        duration: 0.75,
        ease: [0.33, 1, 0.68, 1],
      },
    },
  };

  return (
    <div className={`${styles.body} ${classes}`}>
      <div className={styles.lineMask}>
        <motion.p
          variants={animation}
          animate={isHovered ? "enter" : "initial"}
          initial="initial"
        >
          {hoverText}
        </motion.p>
      </div>
    </div>
  );
};

export const NavLinks = ({ onClick }: { onClick: () => void }) => {
  const translate = useTranslations("nav");
  const [hoveredItem, setHoveredItem] = useState(null);
  const pathname = usePathname();
  const locale = useLocale();
  const handleHover = (index: number) => {
    setHoveredItem(index);
  };

  const handleUnhover = () => {
    setHoveredItem(null);
  };
  const getHrefLink = (href: { es: string; en: string }) => {
    const currentHref = locale === "es" ? href.es : href.en;
    return currentHref;
  };
  const isActiveRoute = (href: { es: string; en: string }) => {
    return pathname === getHrefLink(href);
  };
  const textHoverLocale = (text: { es: string; en: string }) => {
    return locale === "es" ? text.es : text.en;
  };
  return navItems.map((item, i) => (
    <div key={i} className={styles.link}>
      <LinkTransition
        fromNav={true}
        onClick={onClick}
        href={getHrefLink(item.href)}
        className={`${styles.linkLabel} ${
          isActiveRoute(item.href) ? styles.active : ""
        }`}
        aria-label={item.ariaLabel}
        onMouseEnter={() => handleHover(i)}
        onMouseLeave={handleUnhover}
      >
        {translate(item.key)}
      </LinkTransition>
      <MaskText
        hoverText={textHoverLocale(item.text)}
        isHovered={i === hoveredItem}
        classes={isActiveRoute(item.href) ? styles.active : ""}
      />
    </div>
  ));
};
