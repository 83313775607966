export const navItems = [
  {
    key: "home",
    href: { es: "/", en: "/en" },
    text: {
      es: "Los disruptivo también puede ser usable",
      en: "Disruptive can also be usable",
    },

    ariaLabel: "inicio",
  },
  {
    key: "about",
    href: { es: "/nosotros", en: "/en/about" },
    text: {
      es: "Conoce nuestro equipo de más de 20 expertos",
      en: "Meet our team of more than 20 experts",
    },
    ariaLabel: "go to nosotros",
  },
  {
    key: "projects",
    href: { es: "/proyectos", en: "/en/projects" },
    text: {
      es: "Más de 150 proyectos exitosos por el mundo",
      en: "More than 150 successful projects around the world",
    },
    ariaLabel: "go to proyectos",
  },
  {
    key: "services",
    href: { es: "/servicios", en: "/en/services" },
    text: {
      es: "Servicios para llevar tu proyecto al éxito",
      en: "Services to take your project to success",
    },
    ariaLabel: "go to servicios",
  },
  {
    key: "blog",
    href: { es: "/blog", en: "/en/blog" },
    text: {
      es: "Lectura que te puede ser útil",
      en: "Reading that may be useful",
    },
    ariaLabel: "go to blog page",
  },
];

export const navSocial = [
  {
    icon: "Clutch",
    href: "https://clutch.co/profile/tuxdi#highlights",
    ariaLabel: "check our clutch",
  },
  {
    icon: "LinkedIn",
    href: "https://www.linkedin.com/company/tuxdi",
    ariaLabel: "check our linkedin",
  },
  {
    icon: "Facebook",
    href: "https://www.facebook.com/tuxdiARG/",
    ariaLabel: "check our facebook",
  },
  {
    icon: "Instagram",
    href: "https://www.instagram.com/tuxdidigital",
    ariaLabel: "check our instagram",
  },
];

export const navData = [
  {
    icon: "Email",
    href: "mailto:info@tuxdi.com",
    label: "info@tuxdi.com",
  },
  {
    icon: "Phone",
    href: "tel:+542494698992",
    label: "+54 (249) 469 8992",
  },
];

export const menuSlide = {
  initial: { x: "calc(100% + 100px)" },
  enter: { x: "0", transition: { duration: 0.6, ease: [0.76, 0, 0.24, 1] } },
  exit: {
    x: "calc(100% + 100px)",
    transition: { duration: 1.5, ease: [0.76, 0, 0.24, 1] },
  },
};

export const slide = {
  initial: { x: 80 },
  enter: (i: number) => ({
    x: 0,
    transition: { duration: 0.6, ease: [0.76, 0, 0.24, 1], delay: 0.05 * i },
  }),
  exit: (i: number) => ({
    x: 80,
    transition: { duration: 0.6, ease: [0.76, 0, 0.24, 1], delay: 0.05 * i },
  }),
};
