"use client";
import { useCallback, useEffect, useState } from "react";
export const useDeviceDetector = () => {
  const checkIsMobile = useCallback(() => {
    if (typeof window === "undefined" || typeof navigator === "undefined") {
      return false;
    }
    const userAgent = navigator.userAgent || navigator.vendor;
    const isUserAgentMobile =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent.toLowerCase()
      );
    const isResolutionMobile = window.innerWidth < 1000;
    return isUserAgentMobile || isResolutionMobile;
  }, []);

  const [isMobile, setIsMobile] = useState(() => checkIsMobile());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkIsMobile());
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [checkIsMobile]);

  return { isMobile };
};
