export const validateEmail = (mail) => {
  const emailRegex =
    /^(?!.*@(?:mailnesia|yopmail)\.(?:com)$)\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b$/;
  return emailRegex.test(mail);
};

export const validatePhoneNumber = (phone) => {
  const phoneRegex = /^[0-9]*$/;
  return phoneRegex.test(phone);
};
