import React from "react";

export const LogoBlog = () => {
  return (
    <svg
      width="105"
      height="40"
      viewBox="0 0 105 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8878_25762)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11.038V6.58031H2.35001C2.35001 4.38623 2.33644 2.19603 2.31511 0.00195312C3.81199 0.00195312 5.30886 0.00388623 6.80379 0.00388623V6.58031H14.389V11.038H6.8387V20.6243C6.8387 23.1702 8.95216 25.2328 11.5601 25.2328C16.3221 25.2328 9.62691 25.2328 14.389 25.2328V29.6152H11.5601C6.50326 29.6152 2.35001 25.6465 2.35001 20.5644V11.038H0Z"
          fill="#4C2882"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.171 25.2326C23.5631 25.2326 21.4496 23.1699 21.4496 20.624V6.5781H16.9609V20.5622C16.9609 25.6443 21.1142 29.613 26.171 29.613C31.2588 29.613 35.383 25.5883 35.383 20.6221V6.57617H30.8943V20.6221C30.8943 23.168 28.7808 25.2306 26.1729 25.2306L26.171 25.2326Z"
          fill="#4C2882"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.1944 25.2328C65.5865 25.2328 63.4731 23.1702 63.4731 20.6243V15.5634C63.4731 13.0175 65.5865 10.9549 68.1944 10.9549H72.0316V6.57256H68.1944C63.2132 6.57256 59.0173 10.4813 58.9844 15.5016V20.6224C58.9844 25.6678 63.19 29.6132 68.1944 29.6132H69.8193C74.8256 29.6132 79.0312 25.6678 79.0312 20.6224V0H74.5077V20.4387C74.5077 22.9576 72.3418 25.2309 69.8212 25.2309C66.1643 25.2309 71.8532 25.2309 68.1964 25.2309L68.1944 25.2328Z"
          fill="#4C2882"
        />
        <path
          d="M86.172 6.57812H81.6484V29.6149H86.172V6.57812Z"
          fill="#4C2882"
        />
        <path d="M86.172 0H81.6484V4.50993H86.172V0Z" fill="#4C2882" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.7979 29.6149H60.3336L52.1842 18.0956L60.3336 6.57812H54.7979L46.6484 18.0956L54.7979 29.6149Z"
          fill="#4C2882"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.2701 25.7028H35.7344L43.8819 14.1835L35.7344 2.66602H41.2701L49.4195 14.1835L41.2701 25.7028Z"
          fill="#AF2EFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.269 27.6387V34.9114H97.4513L93.0876 37.8205L93.8149 34.9114H88.7266V27.6387H103.269ZM93.0905 32.001C92.6888 32.001 92.3632 31.6754 92.3632 31.2738C92.3632 30.8721 92.6888 30.5465 93.0905 30.5465C93.4921 30.5465 93.8177 30.8721 93.8177 31.2738C93.8177 31.6754 93.4921 32.001 93.0905 32.001ZM95.2694 31.2738C95.2694 31.6754 95.595 32.001 95.9967 32.001C96.3984 32.001 96.724 31.6754 96.724 31.2738C96.724 30.8721 96.3984 30.5465 95.9967 30.5465C95.595 30.5465 95.2694 30.8721 95.2694 31.2738ZM98.903 32.001C98.5013 32.001 98.1757 31.6754 98.1757 31.2738C98.1757 30.8721 98.5013 30.5465 98.903 30.5465C99.3046 30.5465 99.6302 30.8721 99.6302 31.2738C99.6302 31.6754 99.3046 32.001 98.903 32.001Z"
          fill="#AF2EFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_8878_25762">
          <rect width="104.727" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
