import { apiAdapter } from "@/components/common/Dialog/adapter-form";
import { API_URL, token } from "../constants/config";

export async function getFormContactTexts(locale: string) {
  const localeParam = locale === "en" ? `&locale=${locale}` : "";
  const url = `${API_URL}contact-form?populate=questions,final_form,feedback${localeParam}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return apiAdapter(data, locale);
  } catch (error) {
    return apiAdapter({}, locale);
  }
}

export async function sendFormContact(formData: any) {
  const url = `${API_URL}formulario-contactos`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending form data:", error);
    throw error;
  }
}
