"use client";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../Header.module.scss";
import { gsap } from "gsap";
import { useDeviceDetector } from "@/utils/useDeviceDetector";

export const Logo = ({ isActive, hideLogo }) => {
  const smallPath = useRef(null);
  const mainPath = useRef(null);
  const containerRef = useRef(null);
  const { isMobile } = useDeviceDetector();
  const [logoShrinked, setLogoShrinked] = useState(false);
  const handleHideLogo = useCallback(() => {
    const timeline = gsap.timeline();

    if (!logoShrinked) {
      setLogoShrinked(true);

      const paths = document.querySelectorAll('path[fill="#4C2882"]');

      const containerBox = containerRef.current.getBoundingClientRect();
      const mainPathBox = mainPath.current.getBoundingClientRect();

      const mainPathOffset = containerBox.left - mainPathBox.left;

      let smallPathBoxAdjustment: number = 0;
      let smallPathBoxPlus: boolean = false;

      if (typeof window !== undefined) {
        if (window.matchMedia("(max-width: 999px)").matches) {
          smallPathBoxAdjustment = 8.75;
          smallPathBoxPlus = true;
        } else {
          smallPathBoxAdjustment = 0.65;
          smallPathBoxPlus = false;
        }
      }

      const smallPathBox = smallPath.current.getBoundingClientRect();
      const smallPathOffset =
        containerBox.left - smallPathBox.left + +mainPathBox.width;

      const finalSmallPathOffset = smallPathBoxPlus
        ? smallPathOffset - smallPathBoxAdjustment
        : smallPathOffset + smallPathBoxAdjustment;
      timeline
        .to(paths, {
          opacity: 0,
          duration: 0.125,
        })
        .to(
          mainPath.current,
          {
            x: isMobile ? mainPathOffset - 6 : mainPathOffset,
            duration: 0.125,
          },
          "<"
        )
        .to(
          smallPath.current,
          {
            x: isMobile ? finalSmallPathOffset - 6 : finalSmallPathOffset,
            duration: 0.125,
          },
          "<"
        );
    }

    return () => timeline.kill();
  }, [logoShrinked]);

  const handleShowLogo = useCallback(() => {
    const timeline = gsap.timeline();

    if (logoShrinked) {
      setLogoShrinked(false);
      const paths = document.querySelectorAll('path[fill="#4C2882"]');

      timeline
        .to(paths, {
          opacity: 1,
          duration: 0.125,
        })
        .to(
          mainPath.current,
          {
            x: 0,
            duration: 0.125,
          },
          "<"
        )
        .to(
          smallPath.current,
          {
            x: 0,
            duration: 0.125,
          },
          "<"
        );
    }

    return () => timeline.kill();
  }, [logoShrinked]);

  const handleMenuOpens = useCallback(() => {
    if (!logoShrinked) {
      handleHideLogo();
    }

    const timeline = gsap.timeline();

    timeline.to([mainPath.current, smallPath.current], {
      fill: "var(--white)",
      duration: 0.125,
      delay: 0.325,
    });

    return () => timeline.kill();
  }, [logoShrinked]);

  const handleMenuCloses = useCallback(() => {
    const timeline = gsap.timeline();

    timeline.to([mainPath.current, smallPath.current], {
      fill: "var(--secondary)",
      duration: 0.125,
    });

    return () => timeline.kill();
  }, []);

  useEffect(() => {
    if (isActive) {
      handleMenuOpens();
    } else {
      handleMenuCloses();
    }

    if (!hideLogo) {
      handleHideLogo();
      return;
    } else {
      handleShowLogo();
      return;
    }
  }, [hideLogo, isActive]);

  useEffect(() => {
    const handleLogoColored = (event) => {
      if (event.detail) {
        gsap.to([mainPath.current, smallPath.current], {
          fill: "var(--white)",
          duration: 0.125,
        });
      } else {
        gsap.to([mainPath.current, smallPath.current], {
          fill: "var(--secondary)",
          duration: 0.125,
        });
      }
    };

    window.addEventListener("logoColoredEvent", handleLogoColored);

    return () =>
      window.removeEventListener("logoColoredEvent", handleLogoColored);
  }, []);

  return (
    <div className={styles.logo}>
      <svg
        width="139"
        height="50"
        viewBox="0 0 139 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={containerRef}
      >
        <g clipPath="url(#clip0_698_1059)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.000976563 15.1771V9.04768H3.23225C3.23225 6.03083 3.21358 3.01929 3.18426 0.00244141C5.24246 0.00244141 7.30066 0.00509943 9.35619 0.00509943V9.04768H19.7858V15.1771H9.40418V28.3582C9.40418 31.8588 12.3102 34.6949 15.896 34.6949C22.4439 34.6949 13.238 34.6949 19.7858 34.6949V40.7206H15.896C8.94295 40.7206 3.23225 35.2637 3.23225 28.2758V15.1771H0.000976563Z"
            fill="#4C2882"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.9875 34.695C32.4017 34.695 29.4957 31.8589 29.4957 28.3583V9.04514H23.3237V28.2733C23.3237 35.2612 29.0344 40.7181 35.9875 40.7181C42.9833 40.7181 48.654 35.1841 48.654 28.3556V9.04248H42.4821V28.3556C42.4821 31.8563 39.5761 34.6924 35.9902 34.6924L35.9875 34.695Z"
            fill="#4C2882"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.7639 34.6951C90.178 34.6951 87.272 31.859 87.272 28.3584V21.3997C87.272 17.8991 90.178 15.063 93.7639 15.063H99.04V9.03726H93.7639C86.9148 9.03726 81.1454 14.4118 81.1001 21.3147V28.3557C81.1001 35.2932 86.8828 40.7182 93.7639 40.7182H95.9981C102.882 40.7182 108.665 35.2932 108.665 28.3557V0H102.445V28.1032C102.445 31.5666 99.4666 34.6925 96.0007 34.6925C90.9725 34.6925 98.7948 34.6925 93.7666 34.6925L93.7639 34.6951Z"
            fill="#4C2882"
          />
          <path
            d="M118.486 9.04541H112.266V40.721H118.486V9.04541Z"
            fill="#4C2882"
          />
          <path d="M118.486 0H112.266V6.20116H118.486V0Z" fill="#4C2882" />
          <path
            d="M138.998 49.998L138.998 43.7969L118.58 43.7969L118.58 49.998L138.998 49.998Z"
            fill="#AF2EFF"
            ref={smallPath}
            data-name="Blink"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.3461 40.721H82.9577L71.7522 24.8819L82.9577 9.04541H75.3461L64.1406 24.8819L75.3461 40.721Z"
            fill="#4C2882"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.743 35.3411H49.1313L60.3341 19.502L49.1313 3.66553H56.743L67.9484 19.502L56.743 35.3411Z"
            fill="#AF2EFF"
            ref={mainPath}
          />
        </g>
        <defs>
          <clipPath id="clip0_698_1059">
            <rect width="139" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

/* 

const containerBox = containerRef.current.getBoundingClientRect();
    const mainPathBox = mainPath.current.getBoundingClientRect();

    const mainPathOffset = containerBox.left - mainPathBox.left;

    const smallPathBox = smallPath.current.getBoundingClientRect();
    const smallPathOffset =
      containerBox.left - smallPathBox.left + +mainPathBox.width;


      .to(
        mainPath.current,
        {
          x: mainPathOffset,
          duration: 0.125,
        },
        "1"
      )
      .to(
        smallPath.current,
        {
          x: smallPathOffset,
          duration: 0.125,
        },
        "1"
      );
*/
