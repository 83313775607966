import styles from "./Nav.module.scss";
import { motion } from "framer-motion";
import { menuSlide, navData, navSocial, slide } from "./NavUtils";
import { Icon } from "@/components/common/Icons/Icon";
import { MagneticCursor } from "@/components/common/MagneticCursor/MagneticCursor";
import { NavLinks } from "./NavLinks";
import { useEffect } from "react";
import { ChipButton } from "@/components/common/Buttons/ChipButton/ChipButton";
import LanguageSwitch from "./LanguageSwitch/LanguageSwitch";

export const Nav = ({ handleCloseMenu, setIsContactOpen }) => {
  const initialPath = `M100 0 L200 0 L200 ${window.innerHeight} L100 ${
    window.innerHeight
  } Q-100 ${window.innerHeight / 2} 100 0`;

  const targetPath = `M100 0 L200 0 L200 ${window.innerHeight} L100 ${
    window.innerHeight
  } Q100 ${window.innerHeight / 2} 100 0`;

  const curve = {
    initial: {
      d: initialPath,
    },
    enter: {
      d: targetPath,
      transition: { duration: 1, ease: [0.76, 0, 0.24, 1] },
    },
    exit: {
      d: initialPath,
      transition: { duration: 0.8, ease: [0.76, 0, 0.24, 1] },
    },
  };
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);
  return (
    <motion.nav
      variants={menuSlide}
      initial="initial"
      animate="enter"
      exit="exit"
      className={styles.navContainer}
    >
      <div className={`${styles.navInnerContainer} container-header`}>
        <div className={styles.navLinksContainer}>
          <div className={styles.navLinks}>
            <NavLinks onClick={handleCloseMenu} />
          </div>
          <ChipButton
            label="contactanos"
            variant="active"
            onClick={() => setIsContactOpen(true)}
            hidden={false}
          />
        </div>
        <div className={styles.navContactsContainer}>
          <div className={styles.navContacts}>
            <LanguageSwitch />
            <div className={styles.navDataItemContainer}>
              {navData.map((item, i) => (
                <motion.div
                  custom={i}
                  variants={slide}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  key={i}
                  className={styles.navDataItem}
                >
                  <Icon name={item.icon} />
                  <a href={item.href}>{item.label}</a>
                </motion.div>
              ))}
            </div>
          </div>
          <div className={styles.navSocialContainer}>
            {navSocial.map((item, i) => (
              <a key={i} href={item.href} aria-label={item.ariaLabel}>
                <Icon name={item.icon} fill="#fff" />
              </a>
            ))}
          </div>
          <div className={styles.navSocialContainerMagnetic}>
            {navSocial.map((item, i) => (
              <motion.div
                key={i}
                custom={i}
                variants={slide}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                <MagneticCursor>
                  <a
                    target="_blank"
                    href={item.href}
                    aria-label={item.ariaLabel}
                  >
                    <Icon name={item.icon} fill="#fff" />
                  </a>
                </MagneticCursor>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <svg className={styles.navCurve}>
        <motion.path
          variants={curve}
          initial="initial"
          animate="enter"
          exit="exit"
        ></motion.path>
      </svg>
    </motion.nav>
  );
};
