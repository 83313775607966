export const apiAdapter = ({ data }: any, locale: string) => {
  const attributes = data?.attributes || {};
  const choices = attributes?.questions?.map(
    (step: any) =>
      step?.choices?.map((choice: any) => choice?.children?.[0]?.text || "") ||
      []
  ) || [[], [], [], []];
  const steps = [
    {
      title: attributes?.questions?.[0]?.title || "",
      description: attributes?.questions?.[0]?.description || "",
      choices: choices[0],
    },
    {
      title: attributes?.questions?.[1]?.title || "",
      description: attributes?.questions?.[1]?.description || "",
      choices: choices[1],
    },
    {
      title: attributes?.questions?.[2]?.title || "",
      description: attributes?.questions?.[2]?.description || "",
      choices: choices[2],
    },
    {
      title: attributes?.final_form?.title || "",
      description: attributes?.final_form?.description || "",
      name: attributes?.final_form?.full_name_label || "",
      email: attributes?.final_form?.email_label || "",
      country: attributes?.final_form?.country_label || "",
      phone: attributes?.final_form?.phone_label || "",
      comment: attributes?.final_form?.comment_label || "",
    },
    {
      title: attributes?.feedback?.title || "",
      description: attributes?.feedback?.description || "",
    },
  ];

  return { steps };
};
