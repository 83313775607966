import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import useModelStore from "../store/model-store";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";

export const loadThreeModels = () => {
  const loader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();

  dracoLoader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.5.7/"
  );
  loader.setDRACOLoader(dracoLoader);
  loader.setMeshoptDecoder(MeshoptDecoder);

  loader.load("/models/duo.glb", (GLTF) => {
    useModelStore.setState({ duoDuckModel: [GLTF.scene, GLTF.animations] });
  });

  loader.load("/models/duck.glb", (GLTF) => {
    useModelStore.setState({ duck: [GLTF.scene, GLTF.animations] });
  });

  loader.load("/models/roman_helmet.glb", (GLTF) => {
    useModelStore.setState({ romanHelmet: [GLTF.scene] });
  });

  loader.load("/models/baseball_cap.glb", (GLTF) => {
    useModelStore.setState({ baseballCap: [GLTF.scene] });
  });

  loader.load("/models/scrub_cap.glb", (GLTF) => {
    useModelStore.setState({ scrubCap: [GLTF.scene] });
  });

  loader.load("/models/detective_hat.glb", (GLTF) => {
    useModelStore.setState({ detectiveHat: [GLTF.scene] });
  });

  loader.load("/models/safety_helmet.glb", (GLTF) => {
    useModelStore.setState({ safetyHelmet: [GLTF.scene] });
  });
};
